
<template>
    <Head>
        <Meta v-if="$global.isProduction" name="robots" content="index, follow" />
        <Meta v-else name="robots" content="noindex, nofollow" />
        <Link rel="icon" type="image/x-icon" href="https://frontend.coassemble.com/favicon.ico" />
        <Link rel="icon" type="image/png" sizes="32x32" href="https://frontend.coassemble.com/favicon-32x32.png" />
        <Link rel="icon" type="image/png" sizes="16x16" href="https://frontend.coassemble.com/favicon-16x16.png" />
    </Head>
    <slot />
</template>

<script>
import useTheme from '@/store/theme';

export default {
    name: 'PublicLayout',
    inject: ['$global'],
    mounted() {
        useTheme.setContrastBreakpoint(0.179);
        useTheme.generate({ colours: {} }, 0.612);
    },
};
</script>
